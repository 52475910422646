import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledTitle = styled.h1`
  ${tw`pt-12 tracking-tighter`}
`

const StyledContainer = styled.div`
  ${tw`prose lg:prose-lg mx-auto my-0 text-center`}
`

const StyledLink = styled(Link)`
  ${tw`hover:no-underline`}
  color: #2b6cb0 !important;
`

const NotFoundPage = ({ data }) => {
  // check if window is undefined to prevent flash of 404 page
  const browser = typeof window !== "undefined" && window
  const siteTitle = data.site.siteMetadata.title

  return (
    <React.Fragment>
      {browser && (
        <Layout title={siteTitle}>
          <SEO title="404: Not Found" />
          <StyledContainer>
            <StyledTitle>Not Found</StyledTitle>
            <p>You just hit a route that doesn&#39;t exist. <StyledLink to={'/'}>Return home.</StyledLink></p>
          </StyledContainer>
        </Layout>
      )}
    </React.Fragment>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
